import {
  MouseEvent as ReactMouseEvent,
  ChangeEvent as ReactChangeEvent,
} from 'react';
import {
  makeStyles,
  TablePagination as MUITablePagination,
} from '@material-ui/core';
import PaginationActions from './pagination-actions';
import { TableInstance } from 'react-table';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'left',
  },
}));

type TablePaginationProps<T extends object> = {
  total: number;
  instance: TableInstance<T>;
};
export default function TablePagination<T extends object>({
  instance,
  total,
}: TablePaginationProps<T>) {
  const {
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = instance;
  const classes = useStyles();
  const handleChangePage = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    gotoPage(page);
  };

  const handleChangeRowsPerPage = (
    event: ReactChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(Number(event.target.value));
  };
  return total ? (
    <MUITablePagination
      className={classes.toolbar}
      rowsPerPageOptions={[10, 25, 50]}
      count={total}
      page={pageIndex}
      rowsPerPage={pageSize}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={PaginationActions}
    />
  ) : null;
}
