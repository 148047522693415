import { TableInstance } from 'react-table';
import { Product } from '../shared/models/product';
import SearchIcon from '@material-ui/icons/Search';
import { Input, makeStyles } from '@material-ui/core';
import { useCallback, ChangeEvent as ReactChangeEvent } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface ProductSearchProps {
  instance: TableInstance<Product>;
}
export default function ProductSearch({ instance }: ProductSearchProps) {
  const {
    totalCount,
    setGlobalFilter,
    state: { globalFilter },
  } = instance;
  const classes = useStyles();
  const onChange = useCallback(
    (event: ReactChangeEvent<HTMLInputElement>) => {
      setGlobalFilter(event.target.value || undefined);
    },
    [setGlobalFilter]
  );
  return (
    <div className={classes.root}>
      <div>
        <SearchIcon />
      </div>
      <Input
        type='search'
        value={globalFilter || ''}
        onChange={onChange}
        placeholder={`${totalCount} products...`}
      />
    </div>
  );
}
