import { TextField } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

type NumberTextFieldProps = {
  name: string;
  label: string;
  control: Control;
  prefix?: string;
  suffix?: string;
  rules?: Object;
  error?: boolean;
  helperText?: string;
};

export default function NumberTextField({
  name,
  label,
  control,
  prefix,
  suffix,
  rules,
  error,
  helperText,
}: NumberTextFieldProps) {
  return (
    <Controller
      render={props => (
        <NumberFormat
          prefix={prefix}
          suffix={suffix}
          thousandSeparator={true}
          customInput={TextField}
          onValueChange={v => props.onChange(v.value)}
          value={props.value}
          fullWidth
          decimalScale={2}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}
