import { DatePicker } from '@material-ui/pickers';
import { Control, Controller } from 'react-hook-form';
import { isDefaultDotnetDate } from '../shared/utility/helpers';

interface OverrideUntilPickerProps {
  control: Control;
  name: string;
  rules?: Object;
  label: string;
}

export default function OverrideUntilPicker({
  control,
  name,
  rules,
  label,
}: OverrideUntilPickerProps) {
  return (
    <Controller
      render={props => (
        <DatePicker
          label={label}
          value={isDefaultDotnetDate(props.value) ? null : props.value}
          onChange={props.onChange}
          format='MM-dd-yy'
          clearable
        />
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}
