import { TableInstance } from 'react-table';
import { useCallback } from 'react';
import { Button, makeStyles, Popover, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  popOver: {
    padding: 24,
  },
  filtersResetButton: {
    position: 'absolute',
    top: 18,
    right: 21,
  },
  popoverTitle: {
    fontWeight: 500,
    padding: '0 24px 24px 0',
    textTransform: 'uppercase',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 218px)',
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 180px)',
    },
    gridColumnGap: 24,
    gridRowGap: 24,
  },
  cell: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
}));

type FilterPageProps<T extends object> = {
  instance: TableInstance<T>;
  anchorEl: Element | null;
  onClose: () => void;
  show: boolean;
};

export default function FilterPage<T extends object>({
  instance,
  anchorEl,
  onClose,
  show,
}: FilterPageProps<T>) {
  const { allColumns, setAllFilters } = instance;
  const classes = useStyles();

  const resetFilters = useCallback(() => {
    setAllFilters([]);
  }, [setAllFilters]);

  return (
    <div>
      <Popover
        onClose={onClose}
        anchorEl={anchorEl}
        open={show}
        id={'popover-filters'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <div className={classes.popOver}>
          <Typography className={classes.popoverTitle}>Filters</Typography>
          <form>
            <Button
              color='primary'
              className={classes.filtersResetButton}
              onClick={resetFilters}>
              Reset
            </Button>
            <div className={classes.grid}>
              {allColumns
                .filter(it => it.canFilter)
                .map(column => (
                  <div key={column.id} className={classes.cell}>
                    {column.render('Filter')}
                  </div>
                ))}
            </div>
          </form>
        </div>
      </Popover>
    </div>
  );
}
