import { IToastService } from './toast-service';
import {
  PaginationResponse,
  ProductRevision,
  ProductUploadRevision,
} from './../models/product';
import { AuthConfig } from './../../startup/getAuthConfig';
import {
  AuthenticationParameters,
  ClientAuthError,
  InteractionRequiredAuthError,
  UserAgentApplication,
} from 'msal';
import { Filters, SortingRule } from 'react-table';
import {
  Product,
  ProductPagination,
  ProductStatusOptions,
} from '../models/product';

export interface IProductsService {
  getProducts: (props: GetDataProps<Product>) => Promise<PaginationResponse>;
  getProduct: (id: string) => Promise<Product>;
  getOptions: (column: string) => Promise<string[]>;
  exportProducts: (props: ExportProps<Product>) => Promise<void>;
  exportPriceSheet: (props: ExportPriceSheetProps) => Promise<void>;
  revisePrice: (props: RevisePriceProps) => Promise<string>;
  changeStatus: (props: ChangeStatusProps) => Promise<string>;
  exportRevisionProducts: (props: ExportProps<Product>) => Promise<void>;
  uploadProducts:(props: UploadProductsProps) => Promise<string>;
}

type GetDataProps<T extends object> = {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingRule<T>[];
  filters: Filters<T>;
  search: string;
  showApproved: boolean;
};

export type ExportProps<T extends object> = {
  sortBy: SortingRule<T>[];
  filters: Filters<T>;
  search: string;
  showApproved: boolean;
};

export type ExportPriceSheetProps = {
  type: 'current' | 'last';
};

export type RevisePriceProps = {
  id: string;
  data: ProductRevision;
};

export type ChangeStatusProps = {
  productIds: string[];
  status: ProductStatusOptions;
};

export type UploadProductsProps = {
  products: ProductUploadRevision[];
};

type Case =
  | string
  | boolean
  | number
  | (() => string | boolean | number | void);
type SwitchCases = { [key: string]: Case } & Object;

//const executeIfFunction = (f: Case) => (f instanceof Function ? f() : f);
const switchCase = (cases: SwitchCases) => (defaultCase: Case) => (
  key: string
) => (cases.hasOwnProperty(key) ? cases[key] : defaultCase);

export default class ProductsService implements IProductsService {
  constructor(
    private userManager: UserAgentApplication,
    private authConfig: AuthConfig,
    private toast: IToastService,
    private baseUrl: string
  ) {}
  async getProducts(props: GetDataProps<Product>) {
    const { pageIndex, pageSize } = props;
    const token = await this.getToken();
    const url = this.getUrlByFilters(
      `${this.baseUrl}/api/products?pageSize=${pageSize}&pageNumber=${pageIndex}`,
      props
    );

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }

    const pagination: ProductPagination = JSON.parse(
      response.headers.get('X-Pagination') || '{}'
    );
    const products: Product[] = await response.json();
    return {
      products,
      ...pagination,
    };
  }

  async getProduct(id: string) {
    const token = await this.getToken();
    const url = `${this.baseUrl}/api/products/${id}`;

    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(`${error.statusCode}: ${error.message}`);
    }
    const product: Product = await response.json();
    return product;
  }

  async getOptions(column: string) {
    const token = await this.getToken();
    const url = `${this.baseUrl}/api/products/filters?column=${column}`;
    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }
    const options: string[] = await response.json();
    return options;
  }

  async exportProducts(props: ExportProps<Product>) {
    const token = await this.getToken();

    const url = this.getUrlByFilters(
      `${this.baseUrl}/api/export/products?`,
      props
    );
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'text/csv',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }
    const fileName =
      response.headers.get('Content-Disposition')?.split('=')[1] ||
      'productExport.csv';
    const blob = await response.blob();

    this.handleBlob(fileName, blob);
    this.toast.success('Export Complete');
  }

  async exportRevisionProducts(props: ExportProps<Product>) {
    const token = await this.getToken();

    const url = this.getUrlByFilters(
      `${this.baseUrl}/api/export/revise-products?`,
      props
    );
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'text/csv',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }
    const fileName =
      response.headers.get('Content-Disposition')?.split('=')[1] ||
      'productExport.csv';
    const blob = await response.blob();

    this.handleBlob(fileName, blob);
    this.toast.success('Export Complete');
  }

  async exportPriceSheet({ type }: ExportPriceSheetProps) {
    const token = await this.getToken();
    const url = `${this.baseUrl}/api/export/price-sheet/${type}`;

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'text/csv',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }
    const fileName =
      response.headers.get('Content-Disposition')?.split('=')[1] ||
      'priceSheetExport.csv';
    const blob = await response.blob();

    this.handleBlob(fileName, blob);
    this.toast.success('Export Complete');
  }

  async uploadProducts(data: UploadProductsProps){
    const token = await this.getToken();
    const url = `${this.baseUrl}/api/products/upload`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }

    const successMessage: string = await response.json();
    // this.toast.success(successMessage);
    return successMessage;
  }

  async revisePrice({ id, data }: RevisePriceProps) {
    const token = await this.getToken();
    const url = `${this.baseUrl}/api/products/${id}/revise`;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }
    const successMessage: string = await response.json();
    this.toast.success(successMessage);
    return successMessage;
  }

  async changeStatus({ productIds, status }: ChangeStatusProps) {
    const token = await this.getToken();
    const url = `${this.baseUrl}/api/products/status`;
    const payload = {
      productIds,
      status,
    };

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.Message);
    }
    const successMessage: string = await response.json();
    this.toast.success(successMessage);
    return successMessage;
  }

  private getUrlByFilters(
    url: string,
    { showApproved, sortBy, filters, search }: ExportProps<Product>
  ) {
    if (showApproved) {
      url = url.concat(`&showApproved=${showApproved}`);
    }

    if (sortBy.length) {
      url = this.addSort(url, sortBy);
    }
    if (filters.length) {
      url = this.addFilters(url, filters);
    }
    if (search) {
      url = this.addSearch(url, search);
    }
    return url;
  }

  private addSearch(url: string, search: string) {
    return url.concat(`&search=${encodeURIComponent(search)}`);
  }

  private addSort(url: string, sort: SortingRule<Product>[]) {
    return url.concat(
      `&sort=${encodeURIComponent(
        sort.map(({ id, desc }) => `${id}${desc ? ' desc' : ''}`).join()
      )}`
    );
  }

  private addFilters(url: string, filters: Filters<Product>) {
    return url.concat(
      `&filter=${encodeURIComponent(
        filters
          .map(
            ({ id, value }) =>
              `${id};${switchCase({ lastApproved: 'ge' })('eq')(id)};${value}`
          )
          .join()
      )}`
    );
  }

  private handleBlob(fileName: string, blob: Blob) {
    const blobUrl = URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.parentNode?.removeChild(link);
  }

  private async getToken() {
    const tokenRequest: AuthenticationParameters = {
      scopes: [this.authConfig.scope],
    };
    const authResponse = await this.userManager
      .acquireTokenSilent(tokenRequest)
      .catch(error => {
        const popupRequired =
          error instanceof InteractionRequiredAuthError ||
          error instanceof ClientAuthError;
        if (!popupRequired) throw error;
        return this.userManager.acquireTokenPopup(tokenRequest);
      });
    return authResponse.accessToken;
  }
}
