import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useCallback } from 'react';
import { TableInstance } from 'react-table';
import { Product } from '../shared/models/product';

type ShowAllCheckboxProps = {
  instance: TableInstance<Product>;
};
export default function ShowAllCheckbox({ instance }: ShowAllCheckboxProps) {
  const {
    state: { showAll },
    toggleShowAll,
  } = instance;
  const handleChange = useCallback(() => {
    toggleShowAll();
  }, [toggleShowAll]);

  return (
    <FormControlLabel
      control={
        <Checkbox color='primary' checked={showAll} onChange={handleChange} />
      }
      label={`Show Approved`}
    />
  );
}
