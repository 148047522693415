import { Link as RouterLink } from 'react-router-dom';
import { Link, Badge } from '@material-ui/core';
import { UseTableCellProps } from 'react-table';
import { Product } from '../../shared/models/product';
import { useQueryClient } from 'react-query';

export default function ProductDetailColumn(props: UseTableCellProps<Product>) {
  const queryClient = useQueryClient();
  const {
    row: { original },
  } = props;

  const hasCache = queryClient.getQueryData(['product', original.productCode]);
  const quantityBreaksUsed = original.quantityBreaksUsed === 'Y';
  return (
    <Badge title='Quantity Break Used' color='secondary' variant='dot' invisible={!quantityBreaksUsed}>
      <Link
        color={hasCache ? 'secondary' : 'primary'}
        component={RouterLink}
        to={`product/${original.productCode}`}>
        {original.productName || 'No Name'}
      </Link>
    </Badge>
  );
}
