import { useState, MouseEvent as ReactMouseEvent, useCallback } from 'react';

export default function useMenu(): [
  HTMLElement | null,
  (event: ReactMouseEvent<HTMLButtonElement>) => void,
  () => void,
  boolean
] {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: ReactMouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return [anchorEl, openMenu, closeMenu, open];
}
