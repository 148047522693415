import { UseTableCellProps } from 'react-table';
import { Product } from '../../shared/models/product';
import { makeStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import { compareToMarginFloor } from '../../shared/utility/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uhOh: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white, //remove
    borderRadius: 20,
  },
}));

export default function PercentColumn(props: UseTableCellProps<Product>) {
  const classes = useStyles();
  const { value, row, column } = props;
  const margin = Number(value * 100);
  const applyColor = compareToMarginFloor(margin, row.original.marginFloor);
  const notPriceChange = column.id !== 'priceChange'; // possibly move this to own component?
  const className = clsx(classes.root, { [classes.uhOh]: notPriceChange && applyColor });
  return (
    <div className={className}>
      <NumberFormat
        value={margin.toFixed(1)}
        displayType='text'
        thousandSeparator={true}
        suffix={'%'}
      />
    </div>
  );
}
