import { TableInstance } from 'react-table';
import {
  DialogContentText,
  DialogContent,
  DialogActions,
  makeStyles,
  Button,
  Divider,
} from '@material-ui/core';
import { Product } from '../shared/models/product';
import { useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useService } from '../shared/contexts/service-context';
import { useMutation, useQueryClient } from 'react-query';
import { RevisePriceProps } from '../shared/services/product-service';
import NumberTextField from './number-text-field';
import clsx from 'clsx';
import RevisionSelect from './revision-select';
import OverrideUntilPicker from './override-until-picker';
import {
  calculateMargin,
  compareToMarginFloor,
} from '../shared/utility/helpers';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '49% 1% 49%',
    gridTemplateAreas: 'revise divider quantity',
  },
  revise: {
    minHeight: 300,
    padding: 10,
  },
  divider: {
    width: 2,
  },
  quantity: {
    padding: 10,
  },
  warning: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white, //remove
    borderRadius: 20,
  },
}));
interface EditPageProps {
  instance: TableInstance<Product>;
  handleClose: () => void;
}

export default function EditPage({ instance, handleClose }: EditPageProps) {
  const classes = useStyles();
  const { selectedFlatRows } = instance;
  const { productService, toastService } = useService();
  const row = useMemo(
    () => selectedFlatRows?.[0]?.original || { productCode: '' },
    [selectedFlatRows]
  );
  const { control, handleSubmit, watch, formState, setValue, errors } = useForm(
    {
      defaultValues: row,
    }
  );
  const id = row.productCode;
  const revisedPrice = watch('revisedPrice');

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<string, Error, RevisePriceProps>(
    request => productService.revisePrice(request),
    {
      onSuccess: () => {
        handleClose();
        queryClient.invalidateQueries('products');
      },
      onError: error => {
        handleClose();
        toastService.error(error.message);
      },
    }
  );

  const productMargin = calculateMargin(revisedPrice, row.productCostUnit);

  const onSubmit = useCallback(
    data => {
      const request = {
        id,
        data,
      };
      mutate(request);
    },
    [id, mutate]
  );

  const quantityBreaks = useMemo(
    () => [
      { name: 'quantityBreak2', label: 'Quantity 50' },
      { name: 'quantityBreak3', label: 'Quantity 100' },
      { name: 'quantityBreak4', label: 'Quantity 150' },
      { name: 'quantityBreak5', label: 'Quantity 200' },
    ],
    []
  );

  const showWarning = compareToMarginFloor(productMargin, row.marginFloor);

  const quantityBreaksUsed = row.quantityBreaksUsed === 'Y';
  const divClassName = clsx({ [classes.grid]: quantityBreaksUsed });
  const resetToCurrentPrice = useCallback(() => {
    setValue('revisedPrice', row.currentPrice);
  }, [row, setValue]);
  const resetToRecommendedPrice = useCallback(() => {
    setValue('revisedPrice', row.recommendedPrice);
  }, [row, setValue]);

  return (
    <DialogContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={divClassName}>
          <div className={classes.revise}>
            <DialogContentText>Revise</DialogContentText>
            <div>
              Current Price:{' '}
              <Button onClick={resetToCurrentPrice}>
                <strong>${Number(row.currentPrice).toFixed(2)}</strong>
              </Button>
            </div>
            <div>
              Recommended Price:{' '}
              <Button onClick={resetToRecommendedPrice}>
                <strong>${Number(row.recommendedPrice).toFixed(2)}</strong>
              </Button>
            </div>
            <p>
              New Product Margin:{' '}
              <strong>
                {Number.isFinite(productMargin) ? productMargin.toFixed(1) : 0}
              </strong>
              %
            </p>
            <NumberTextField
              control={control}
              prefix='$ '
              name='revisedPrice'
              label='Revised Price'
            />
            <RevisionSelect
              control={control}
              name='revisionReason'
              label='Select a reason'
              rules={{
                required: { value: true, message: 'Reason is required' },
              }}
              error={Boolean(errors.revisionReason)}
              helperText={
                Boolean(errors.revisionReason)
                  ? errors.revisionReason?.message
                  : ''
              }
              // disabled={!formState.isDirty}
            />
            <OverrideUntilPicker
              control={control}
              name='overrideUntil'
              label='Override Until'
            />
            {showWarning ? (
              <p className={classes.warning}>
                <strong>Price is below Margin Floor</strong>
              </p>
            ) : null}
          </div>
          {quantityBreaksUsed && (
            <>
              <Divider className={classes.divider} orientation='vertical' />
              <div className={classes.quantity}>
                <DialogContentText>Quantity Breaks</DialogContentText>
                {quantityBreaks.map(({ name, label }) => (
                  <NumberTextField
                    key={name}
                    control={control}
                    prefix='$ '
                    name={name}
                    label={label}
                  />
                ))}
              </div>
            </>
          )}
          {/* <pre>{JSON.stringify(row, null, 2)}</pre> */}
        </div>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!formState.isDirty}
            type='submit'
            variant='contained'
            color='primary'>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </DialogContent>
  );
}
