import { useState, useCallback } from 'react';

export default function useModal(): [boolean, () => void] {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return [open, toggle];
}
