import {
    AppBar,
    Box,
    Button,
    makeStyles,
    Menu,
    MenuItem,
    Toolbar,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { FC } from 'react';
import { useAuth } from '../../contexts/auth-context';
import useMenu from '../../hooks/useMenu';
import Logo from './logo';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    user: {
        width: 350,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

type HeaderProps = {};
const Header: FC<HeaderProps> = () => {
    const classes = useStyles();
    const { userManager } = useAuth();
    const userAccount = userManager.getAccount();
    const authenticated = Boolean(userAccount);
    const [anchorEl, openMenu, closeMenu, open] = useMenu();
    const logout = () => {
        userManager.logout();
    };
    const buttonName = 'action';
    const menuName = 'logout-menu';
    return (
        <AppBar position='static'>
            <Toolbar className={classes.root}>
                <Logo />
                {authenticated && (
                    <>
                        <Button
                            size='large'
                            id={buttonName}
                            aria-controls={open ? menuName : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={openMenu}
                            color='inherit'>
                            <AccountCircle />
                        </Button>
                        <Menu
                            id={'logout-menu'}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={closeMenu}
                            MenuListProps={{
                                'aria-labelledby': buttonName,
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}>
                            <MenuItem onClick={logout}>
                                <Box
                                    display={'flex'}
                                    flexDirection='row'
                                    alignItems={'center'}>
                                    <ExitToAppIcon
                                        style={{ marginRight: '10px' }}
                                    />
                                    Logout
                                </Box>
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
