import { TableInstance } from 'react-table';
import { Toolbar, makeStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterPage from './filter-page';
import ActionButton from './action-button';
import useMenu from '../../hooks/useMenu';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '1fr .5fr 1fr',
  },
  leftButtons: {
    display: 'grid',
    gridTemplateColumns: '.25fr .25fr'
  },
  editButton: {
    marginRight: theme.spacing(3),
  },
  title: {
    textAlign: 'center',
  },
  rightButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr .25fr',
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'center'
  },
}));

type TableToolBarProps<T extends object> = {
  instance: TableInstance<T>;
};
export default function TableToolBar<T extends object>({
  instance,
}: TableToolBarProps<T>) {
  const classes = useStyles();
  const [anchorEl, handleFilterClick, handleClose, filterOpen] = useMenu();
  const {
    editAction,
    exportAction,
    searchAction,
    showAllAction,
    totalCount,
    renderEdit,
  } = instance;
  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.leftButtons}>
        <div className={classes.editButton}>
          {renderEdit && renderEdit(instance)}
          {editAction && editAction(instance)}
        </div>
        {showAllAction && showAllAction(instance)}
      </div>
      <div className={classes.title}>
        <strong>{totalCount} Products in View</strong>
      </div>
      <div className={classes.rightButtons}>
        <div className={classes.searchBar}>{searchAction && searchAction(instance)}</div>
        <div>
          <FilterPage
            instance={instance}
            onClose={handleClose}
            show={filterOpen}
            anchorEl={anchorEl}
          />
          <ActionButton
            icon={<FilterListIcon />}
            onClick={handleFilterClick}
            label='Filters'
          />
          {exportAction && exportAction(instance)}
        </div>
      </div>
    </Toolbar>
  );
}
