import { makeStyles } from '@material-ui/core';
import { Product } from '../../shared/models/product';
import { UseTableCellProps } from 'react-table';
import { format } from 'date-fns';
import { isDefaultDotnetDate } from '../../shared/utility/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
}));

export default function DateColumn(props: UseTableCellProps<Product>) {
  const classes = useStyles();
  const { value } = props;
  return (
    <div className={classes.root}>
      {isDefaultDotnetDate(value)
        ? 'No Approval'
        : format(new Date(value), 'MM-dd-yy p')}
    </div>
  );
}
