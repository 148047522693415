import { IconButton, Tooltip, Menu, MenuItem } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { useMutation, useQueryClient } from 'react-query';
import { TableInstance } from 'react-table';
import ConfirmDialog from '../shared/components/dialogs/confirm-dialog';
import { useService } from '../shared/contexts/service-context';
import useMenu from '../shared/hooks/useMenu';
import useModal from '../shared/hooks/useModal';
import {
  Product,
  ProductStatus,
  ProductStatusOptions,
} from '../shared/models/product';
import { ChangeStatusProps } from '../shared/services/product-service';
import {
  calculateMargin,
  compareToMarginFloor,
} from '../shared/utility/helpers';
import UploadModal from './upload/upload-modal';

interface EditActionButtonProps {
  instance: TableInstance<Product>;
  toggleEditPopup: () => void;
}

export default function EditActionButton({
  instance,
  toggleEditPopup,
}: EditActionButtonProps) {
  const [confirmOpen, toggleConfirm] = useModal();
  const [uploadOpen, toggleUpload] = useModal();
  const [anchorEl, openMenu, closeMenu, open] = useMenu();
  const { productService, toastService } = useService();
  const queryClient = useQueryClient();
  const {
    state: { selectedRowIds },
    selectedFlatRows,
  } = instance;

  const changeStatusMutation = useMutation<string, Error, ChangeStatusProps>(
    request => productService.changeStatus(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
      },
      onError: error => {
        toastService.error(error.message);
      },
    }
  );

  const productIds = Object.keys(selectedRowIds);

  const changeStatus = (status: ProductStatusOptions) => {
    return () => {
      closeMenu();
      if (status === ProductStatus.Approved) {
        toggleConfirm();
        return;
      }
      const request = {
        productIds,
        status,
      };
      changeStatusMutation.mutate(request);
    };
  };

  const productsBelowMargin = selectedFlatRows.filter(row =>
    compareToMarginFloor(
      calculateMargin(row.original.revisedPrice, row.original.productCostUnit),
      row.original.marginFloor
    )
  );

  const items: { name: string; click: () => void; enabled: boolean }[] = [
    {
      name: 'Revise',
      click: () => {
        toggleEditPopup();
        closeMenu();
      },
      enabled:
        productIds.length === 1 &&
        selectedFlatRows[0]?.original.status !== ProductStatus.Approved,
    },
    {
      name: 'divider',
      click: () => {},
      enabled: false,
    },
    {
      name: 'Mark Approved',
      click: changeStatus(ProductStatus.Approved),
      enabled:
        Boolean(productIds.length) &&
        selectedFlatRows.every(
          row => row.original.status !== ProductStatus.Approved
        ),
    },
    {
      name: 'Mark Review',
      click: changeStatus(ProductStatus.Review),
      enabled:
        Boolean(productIds.length) &&
        selectedFlatRows.every(
          row => row.original.status !== ProductStatus.Review
        ),
    },
    {
      name: 'Mark Exec Review',
      click: changeStatus(ProductStatus.ExecReview),
      enabled:
        Boolean(productIds.length) &&
        selectedFlatRows.every(
          row => row.original.status !== ProductStatus.ExecReview
        ),
    },
    {
      name: 'divider',
      click: () => {},
      enabled: false,
    },
    {
      name: 'Upload',
      click: () => {
        closeMenu();
        toggleUpload();
      },
      enabled: true,
    },
  ];

  return (
    <>
      <Tooltip title='Edit'>
        <IconButton onClick={openMenu}>
          <CreateIcon />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        {items.map(({ name, click, enabled }, index) => (
          <MenuItem
            key={index}
            divider={name === 'divider'}
            disabled={!enabled}
            onClick={click}>
            {name === 'divider' ? '' : name}
          </MenuItem>
        ))}
      </Menu>
      <ConfirmDialog
        onConfirm={() => {
          changeStatusMutation.mutate({
            status: ProductStatus.Approved,
            productIds,
          });
        }}
        open={confirmOpen}
        onClose={toggleConfirm}
        title='Approval Confirmation'>
        <p>Are you sure you want to Approve {productIds.length} product(s)</p>
        <p>There are {productsBelowMargin.length} products below margin</p>
      </ConfirmDialog>
      <UploadModal open={uploadOpen} onClose={toggleUpload} />
    </>
  );
}
