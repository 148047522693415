import { useState, useEffect, useCallback } from 'react';

export default function useProgress(): [
  number,
  (count: number) => void,
  () => void
] {
  const [total, setCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [processedCount, setProcessedCount] = useState(0);

  useEffect(() => {
    if (processedCount > 0 && total > 0) {
      setProgress((processedCount / total) * 100);
    }
  }, [processedCount, total]);

  const increment = useCallback(() => {
    setProcessedCount(prev => prev + 1);
  }, []);

  const setTotal = useCallback((count: number) => {
    setProgress(0);
    setProcessedCount(0);
    setCount(count);
  }, []);
  return [progress, setTotal, increment];
}
