import { createContext, PropsWithChildren, useContext } from 'react';
import { IProductsService } from '../services/product-service';
import { IToastService } from '../services/toast-service';

interface ServiceContext {
  productService: IProductsService;
  toastService: IToastService;
}
type ServiceProviderProps = {
  context: ServiceContext;
};

const serviceContext = createContext<ServiceContext>({} as ServiceContext);

export function ProvideService({
  children,
  context,
}: PropsWithChildren<ServiceProviderProps>) {
  return (
    <serviceContext.Provider value={context}>
      {children}
    </serviceContext.Provider>
  );
}

export function useService() {
  return useContext(serviceContext);
}
