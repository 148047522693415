import { useMemo, useCallback } from 'react';
import { Column, TableInstance, TableState } from 'react-table';
import { PaginationResponse, Product } from '../shared/models/product';
import DataTable from '../shared/components/data-table';
import SelectFilter from './filters/select-filter';
import CompetitorColumn from './columns/competitor-column';
import NumberColumn from './columns/number-column';
import PercentColumn from './columns/percent-column';
import EditPage from './edit-page';
import { useService } from '../shared/contexts/service-context';
import ProductSearch from './product-search';
import ProductDetailColumn from './columns/product-detail-column';
import ShowAllCheckbox from './show-all-checkbox';
import { useLocalStorage } from '../shared/hooks/useLocalStorage';
import { useQuery } from 'react-query';
import { Dialog, LinearProgress, makeStyles } from '@material-ui/core';
import DateFilter from './filters/date-filter';
import DateColumn from './columns/date-column';
import ExportActionButton from './export-action-button';
import EditActionButton from './edit-action-button';
import useModal from '../shared/hooks/useModal';

const useStyles = makeStyles(theme => ({
  loadingBar: {
    height: 4,
  },
}));
const defaultState: TableState<Product> = {
  selectedRowIds: {},
  pageIndex: 0,
  pageSize: 10,
  globalFilter: '',
  showAll: false,
  filters: [],
  sortBy: [{ id: 'productTier', desc: false }],
};

export default function ProductList() {
  const classes = useStyles();
  const { productService, toastService } = useService();
  const [editOpen, toggleEditPopup] = useModal();
  const [initialTableState, setInitialTableState] = useLocalStorage<
    TableState<Product>
  >('TableState:Products', defaultState);
  const { data, isFetching, isLoading } = useQuery<PaginationResponse, Error>(
    ['products', initialTableState],
    async () =>
      productService.getProducts({
        pageIndex: initialTableState.pageIndex + 1,
        pageSize: initialTableState.pageSize,
        search: initialTableState.globalFilter,
        showApproved: initialTableState.showAll,
        filters: initialTableState.filters,
        sortBy: initialTableState.sortBy,
      }),
    {
      keepPreviousData: true,
      onError: error => {
        toastService.error(error.message);
      },
    }
  );

  const columns: Column<Product>[] = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectFilter,
        width: 100,
      },
      {
        Header: 'Product Code',
        accessor: 'productCode',
        disableFilters: true,
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        disableFilters: true,
        Cell: ProductDetailColumn,
      },
      {
        Header: 'Competitor Price',
        accessor: 'competitiveSkuPrice',
        disableFilters: true,
        disableSortBy: true,
        Cell: CompetitorColumn,
        width: 160,
      },
      {
        Header: 'Current Price',
        accessor: 'currentPrice',
        Cell: NumberColumn,
        disableFilters: true,
      },
      {
        Header: 'Recommended Price',
        accessor: 'recommendedPrice',
        Cell: NumberColumn,
        disableFilters: true,
      },
      {
        Header: 'Revised Price',
        accessor: 'revisedPrice',
        Cell: NumberColumn,
        disableFilters: true,
      },
      {
        Header: 'Reason',
        accessor: 'reasonForRecommendation',
        Filter: SelectFilter,
      },
      {
        Header: 'Product Margin',
        accessor: 'productMargin',
        Cell: PercentColumn,
        disableFilters: true,
      },
      {
        Header: 'Revised Margin',
        accessor: 'revisedMargin',
        Cell: PercentColumn,
        disableFilters: true,
      },
      {
        Header: 'Price Change %',
        accessor: 'priceChange',
        Cell: PercentColumn,
        disableFilters: true,
      },
      {
        Header: 'Category',
        accessor: 'category',
        Filter: SelectFilter,
      },
      {
        Header: 'Product Tier',
        accessor: 'productTier',
        Filter: SelectFilter,
      },
      {
        Header: 'Last Approved',
        accessor: 'lastApproved',
        Cell: DateColumn,
        Filter: DateFilter,
      },
      {
        Header: 'Quantity Break',
        accessor: 'quantityBreaksUsed',
        Filter: SelectFilter,
        isVisible: false,
      },
    ],
    []
  );

  const getRowId = useCallback((row: Product) => row.productCode, []);

  const showAllCheckBox = useCallback((instance: TableInstance<Product>) => {
    return <ShowAllCheckbox instance={instance} />;
  }, []);

  const renderEdit = useCallback(
    (instance: TableInstance<Product>) => (
      <Dialog disableBackdropClick={true} open={editOpen}>
        <EditPage instance={instance} handleClose={toggleEditPopup} />
      </Dialog>
    ),
    [editOpen, toggleEditPopup]
  );

  const editAction = useCallback(
    (instance: TableInstance<Product>) => (
      <EditActionButton instance={instance} toggleEditPopup={toggleEditPopup} />
    ),
    [toggleEditPopup]
  );

  const exportAction = useCallback(
    (instance: TableInstance<Product>) => (
      <ExportActionButton instance={instance} />
    ),
    []
  );

  const searchAction = useCallback(
    (instance: TableInstance<Product>) => <ProductSearch instance={instance} />,
    []
  );

  return (
    <>
      <div className={classes.loadingBar}>
        {(isFetching || isLoading) && <LinearProgress />}
      </div>
      <DataTable<Product>
        manual={true}
        columns={columns}
        data={data?.products ?? []}
        totalCount={data?.totalCount || 0}
        totalPageCount={data?.totalPages || 0}
        editAction={editAction}
        exportAction={exportAction}
        searchAction={searchAction}
        getRowId={getRowId}
        renderEdit={renderEdit}
        showAllAction={showAllCheckBox}
        initialState={initialTableState}
        saveState={setInitialTableState}
        hiddenColumns={['quantityBreaksUsed', 'sellingUnitType']}
      />
    </>
  );
}
