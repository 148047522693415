import {
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import { parse } from 'papaparse';
import { arrayEquals } from '../utility/helpers';

interface CSVFile<T> {
  name: string;
  data: T[];
}

const defaultFile = { name: 'No file chosen', data: [] };

export default function useCsvFile<T>(
  acceptedFields: string[]
): [
  CSVFile<T>,
  string,
  MutableRefObject<HTMLInputElement | null>,
  () => void,
  () => void,
  () => void
] {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<CSVFile<T>>(defaultFile);
  const [error, setError] = useState('');

  const fieldsAreValidated = arrayEquals(acceptedFields);

  const clearData = () => {
    setFile(defaultFile);
    setError('');
  };

  const onChange = useCallback(() => {
    clearData();
    if (fileRef.current) {
      const userFile = fileRef.current.files?.[0];
      parse<T>(userFile || '', {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: ({ data, meta }) => {
          const fileName = userFile?.name || '';
          if (fieldsAreValidated(meta.fields || [])) {
            setFile({ name: fileName, data });
          } else {
            setFile({ name: fileName, data: [] });
            setError(
              'File format is incorrect! Please make sure format matches export and try again.'
            );
          }
        },
      });
      fileRef.current.value = '';
    }
  }, [fieldsAreValidated]);

  const trigger = useCallback(() => {
    fileRef.current?.click();
  }, []);

  return [file, error, fileRef, onChange, trigger, clearData];
}
