import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    logo: {
        maxHeight: 45,
    },
}));

export default function Logo() {
    const classes = useStyles();
    return <img className={classes.logo} src='/insight-logo.png' alt='' />;
}
