import { Container, Paper, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import Header from '../components/header/header';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '30px',
  },
}));

type AuthLayoutProps = {};
const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <Container className={classes.root} maxWidth={false}>
        <Paper>{children}</Paper>
      </Container>
    </div>
  );
};

export default AuthLayout;
