import 'material-react-toastify/dist/ReactToastify.css';
import { CssBaseline } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'material-react-toastify';
import AuthLayout from './shared/layouts/auth-layout';
import Login from './login/login';
import ProductList from './products/product-list';
import ProductDetail from './product-detail/product-detail';

import ROUTES from './routes';
import PrivateRoute from './shared/components/auth/auth-guard';

const pages = [
  {
    exact: true,
    route: Route,
    path: ROUTES.login,
    component: Login,
    layout: AuthLayout,
  },
  {
    exact: true,
    route: PrivateRoute,
    path: ROUTES.products,
    component: ProductList,
    layout: AuthLayout,
  },
  {
    exact: true,
    route: PrivateRoute,
    path: ROUTES.productDetail,
    component: ProductDetail,
    layout: AuthLayout,
  },
];
function App() {
  return (
    <>
      <CssBaseline />
      <Switch>
        {pages.map(
          (
            { path, exact, component: Component, layout: Layout, route: Route },
            i
          ) => (
            <Route key={i} exact={exact} path={path}>
              <Layout>
                <Component />
              </Layout>
            </Route>
          )
        )}
        <Redirect to={ROUTES.login} />
      </Switch>
      <ToastContainer newestOnTop={true} />
    </>
  );
}

export default App;
