import { Button, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import ROUTES from '../routes';
import { useAuth } from '../shared/contexts/auth-context';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 15,
    height: 200,
  },
}));

export default function Login() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { userManager, authConfig } = useAuth();
  const userAccount = userManager.getAccount();
  if (userAccount !== null) {
    return <Redirect to={ROUTES.products} />;
  }

  const login = async () => {
    setLoading(true);
    await userManager
      .loginPopup({
        scopes: [authConfig.scope],
      })
      .catch(console.error);
    history.push(ROUTES.products);
  };

  return loading ? (
    <div>
      <h1>Signing in...</h1>
    </div>
  ) : (
    <div className={classes.root}>
      <Typography variant='h3'>Demo Price Management Tool</Typography>
      <Button size='large' variant='contained' color='primary' onClick={login}>
        Login
      </Button>
    </div>
  );
}
