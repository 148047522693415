import { Tooltip, IconButton, Menu, MenuItem, Icon } from '@material-ui/core';
import { useMutation } from 'react-query';
import { TableInstance } from 'react-table';
import { useService } from '../shared/contexts/service-context';
import useMenu from '../shared/hooks/useMenu';
import { Product } from '../shared/models/product';
import {
  ExportPriceSheetProps,
  ExportProps,
} from '../shared/services/product-service';

type ExportActionButtonProps = {
  instance: TableInstance<Product>;
};

export default function ExportActionButton({
  instance,
}: ExportActionButtonProps) {
  const [anchorEl, openMenu, closeMenu, open] = useMenu();
  const { productService, toastService } = useService();
  const {
    totalCount,
    state: { sortBy, filters, globalFilter, showAll },
  } = instance;

  const exportMutation = useMutation<void, Error, ExportProps<Product>>(
    request => productService.exportProducts(request),
    {
      onError: error => {
        closeMenu();
        toastService.error(error.message);
      },
    }
  );

  const exportRevisionMutation = useMutation<void, Error, ExportProps<Product>>(
    request => productService.exportRevisionProducts(request),
    {
      onError: error => {
        closeMenu();
        toastService.error(error.message);
      },
    }
  );

  const priceSheetMutation = useMutation<void, Error, ExportPriceSheetProps>(
    request => productService.exportPriceSheet(request),
    {
      onError: error => {
        closeMenu();
        toastService.error(error.message);
      },
    }
  );

  const exportProducts = () => {
    exportMutation.mutate({
      sortBy,
      filters,
      search: globalFilter,
      showApproved: showAll,
    });
    closeMenu();
  };

  const exportRevisionProducts = () => {
    exportRevisionMutation.mutate({
      sortBy,
      filters,
      search: globalFilter,
      showApproved: showAll,
    })
  }

  const exportPriceSheetThisWeek = () => {
    priceSheetMutation.mutate({ type: 'current' });
    closeMenu();
  };
  const exportPriceSheetLastWeek = () => {
    priceSheetMutation.mutate({ type: 'last' });
    closeMenu();
  };
  return (
    <>
      <Tooltip title='Export'>
        <IconButton onClick={openMenu}>
          <Icon>download</Icon>
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem onClick={exportProducts}>Export ({totalCount})</MenuItem>
        <MenuItem onClick={exportPriceSheetThisWeek}>
          Price Sheet (This Week)
        </MenuItem>
        <MenuItem onClick={exportPriceSheetLastWeek}>
          Price Sheet (Last Week)
        </MenuItem>
        <MenuItem onClick={exportRevisionProducts}>
          Revise Products ({totalCount})
        </MenuItem>
      </Menu>
    </>
  );
}
