import { MenuItem, TextField } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

type RevisionsSelectProps = {
  name: string;
  control: Control;
  label: string;
  rules?: Object;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
};

const options = [
  '101 – Price Sensitivity Concern',
  '102 – New Product',
  '103 – Inventory Positioning',
  '104 – Revise Primary Competitor',
  '105 – Reseller Competition',
  '106 – Wholesale Conflict',
  '107 – Forecasted Category Changes',
  '108 – Price Laddering',
  '109 – Seasonal',
  '110 - Market Prices Have Not Moved Up',
  '199 - Other',
];
export default function RevisionSelect({
  control,
  name,
  rules,
  label,
  disabled,
  error,
  helperText,
}: RevisionsSelectProps) {
  return (
    <Controller
      render={props => (
        <TextField
          label={label}
          disabled={disabled}
          select
          value={props.value || ''}
          fullWidth
          error={error}
          helperText={helperText}
          onChange={props.onChange}
          onBlur={props.onBlur}>
          {options.map((option, i) => (
            <MenuItem value={option} key={i}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
}
