import { toast, ToastOptions } from 'material-react-toastify';
export interface IToastService {
  info: (message: string) => void;
  error: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
}

export default class ToastService implements IToastService {
  toastConfig: ToastOptions;

  constructor() {
    this.toastConfig = {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false
    };
  }

  error(message: string) {
    toast.error(message, this.toastConfig);
  }

  success(message: string) {
    toast.success(message, this.toastConfig);
  }

  warning(message: string) {
    toast.warning(message, this.toastConfig);
  }

  info(message: string) {
    toast.info(message, this.toastConfig);
  }
}
