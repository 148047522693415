import { UseTableCellProps } from 'react-table';
import {
  Popover,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Badge,
  Link,
  makeStyles,
} from '@material-ui/core';
import { startOfWeek } from 'date-fns';

import { Product } from '../../shared/models/product';
import useMenu from '../../shared/hooks/useMenu';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const getMinMax = (arr: number[]) => [Math.min(...arr), Math.max(...arr)];

export default function CompetitorColumn(props: UseTableCellProps<Product>) {
  const classes = useStyles();
  const [anchorEl, handleOpen, handleClose, open] = useMenu();
  const {
    row: { original },
  } = props;

  const [min, max] = getMinMax(original.competitors.map(({ price }) => price));

  const today = new Date();
  return (
    <div className={classes.root}>
      {original.competitors.length ? (
        <>
          <Button onClick={handleOpen}>
            {original.competitors.length <= 1 ? `$${min}` : `$${min}-$${max}`}
          </Button>
          <Popover
            id='mouse-over-popover'
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleClose}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>My Price:</TableCell>
                    <TableCell>{original.currentPrice}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Competitor</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {original.competitors.map(
                    ({ site, description, price, lastUpdated, url }, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Badge
                            title='Competitive Price Change'
                            color='primary'
                            variant='dot'
                            invisible={
                              new Date(lastUpdated) <
                              startOfWeek(today, { weekStartsOn: 0 })
                            }>
                            {url?.startsWith('https://') ? (
                              <Link target='_blank' href={url}>
                                {site}
                              </Link>
                            ) : (
                              site
                            )}
                          </Badge>
                        </TableCell>
                        <TableCell>{description}</TableCell>
                        <TableCell>${price}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Popover>
        </>
      ) : (
        'No Data'
      )}
    </div>
  );
}
