export const DATE_TIME_MIN = '0001-01-01T00:00:00Z';

export const isDefaultDotnetDate = (date: string) => date === DATE_TIME_MIN;

export const calculateMargin = (price: number, cost: number) =>
  ((price - cost) / price) * 100;

export const toFixed = (places: number) => (value: number) =>
  Number(Number(value).toFixed(places));

export const fixed2Places = toFixed(2);

export const compareToMarginFloor = (margin: number, marginFloor: number) =>
  fixed2Places(margin) < fixed2Places(marginFloor);

export const arrayEquals = <T extends string | number>(source: T[]) => (
  target: T[]
) => target.every((x, i) => x === source[i]);

export const chunkArray = <T>(items: T[], chunkSize: number): T[][] =>
  items.reduce<T[][]>((rows, data, index: number) => {
    if (index % chunkSize === 0) {
      rows.push([data]);
    } else {
      rows[Boolean(rows.length) ? rows.length - 1 : 0].push(data);
    }
    return rows;
  }, []);
