import { UseTableCellProps } from 'react-table';
import { Product } from '../../shared/models/product';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function NumberColumn(props: UseTableCellProps<Product>) {
  const classes = useStyles();
  const { value } = props;

  return (
    <div className={classes.root}>
      <NumberFormat
        value={value}
        displayType='text'
        thousandSeparator={true}
        prefix={'$'}
      />
    </div>
  );
}
