import { MenuItem, TextField } from '@material-ui/core';
import { useQuery } from 'react-query';
import { FilterProps } from 'react-table';
import { useService } from '../../shared/contexts/service-context';
import { Product } from '../../shared/models/product';

export default function SelectFilter({
  column: { filterValue, setFilter, render, id },
}: FilterProps<Product>) {
  const { productService, toastService } = useService();
  const { data: options, isLoading } = useQuery<string[], Error>(
    ['filters', id],
    () => productService.getOptions(id),
    {
      onError: error => {
        toastService.error(error.message);
      },
    }
  );

  return (
    <TextField
      select
      label={render('Header')}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}>
      {(isLoading ? ['Loading...'] : options || []).map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
