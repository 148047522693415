import { IconButton, Tooltip } from '@material-ui/core';
import { MouseEventHandler } from 'react';

type ActionButtonProps = {
  icon?: JSX.Element;
  onClick: MouseEventHandler;
  enabled?: boolean;
  label: string;
};

export default function ActionButton({
  icon,
  onClick,
  label,
  enabled = true,
}: ActionButtonProps) {
  return (
    <Tooltip title={label} aria-label={label}>
      <IconButton onClick={onClick} disabled={!enabled}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}
