import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { PropsWithChildren } from 'react';

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  onConfirm: () => void;
};

export default function ConfirmDialog({
  open,
  onClose,
  title,
  children,
  onConfirm,
}: PropsWithChildren<ConfirmDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='confirm-dialog'>
      <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose} color='secondary'>
          No
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            onClose();
            onConfirm();
          }}
          color='default'>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
