import { useHistory, useParams } from 'react-router-dom';
import { useService } from '../shared/contexts/service-context';
import Chart from 'react-apexcharts';
import NumberFormat from 'react-number-format';
import { getYear, subYears, startOfWeek, addDays } from 'date-fns';
import {
  Button,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { History } from '../shared/models/product';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: 'details history',
    padding: 10,
  },
  gridDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: 'one two',
    gap: 10,
    padding: 10,
  },
  loadingRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  goBack: {
    margin: theme.spacing(1),
  },
}));

function uniqueByProperty<T>(property: keyof T, mapper?: (value: any) => any) {
  return (list: T[]): T[] => [
    ...list
      .reduce((map, item) => {
        const key = mapper ? mapper(item[property]) : item[property];
        map.set(key, item);
        return map;
      }, new Map())
      .values(),
  ];
}

const byStartOfWeek = (date: string) => startOfWeek(addDays(new Date(date), 1)).getTime();

const toNumber = (value?: string | number, percent: boolean = false) => () => (
  <NumberFormat
    value={value}
    displayType='text'
    thousandSeparator={true}
    prefix={percent ? undefined : '$'}
    suffix={percent ? '%' : undefined}
  />
);

const toText = (text?: string) => () => text;

export default function ProductDetail() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { productService, toastService } = useService();
  const goBack = () => {
    history.goBack();
  };

  const getUniqueDate = uniqueByProperty<History>('updatedAt', byStartOfWeek);

  const { data: product, isLoading } = useQuery(
    ['product', id],
    () => productService.getProduct(id),
    {
      onError: (error: Error) => {
        toastService.error(error.message);
      },
    }
  );

  const belnickData = getUniqueDate(product?.productHistory || []).map(
    history => ({
      x: byStartOfWeek(history.updatedAt),
      y: history.newPrice,
    })
  );

  const competitorData = (product?.competitors || []).map((competitor, i) => ({
    // name: competitor.description,
    name: `competitor ${i + 1}`, // remove
    data: getUniqueDate(competitor.competitorHistory).map(x => ({
      x: byStartOfWeek(x.updatedAt),
      y: x.newPrice,
    })),
  }));

  const chartOptions = {
    stroke: {
      curve: 'straight',
      lineCap: 'round',
    },
    xaxis: {
      type: 'datetime',
    },
  };

  const chartData = [
    {
      name: 'insight2profit',
      data: belnickData,
    },
    ...competitorData,
  ];

  if (isLoading) {
    return (
      <div className={classes.loadingRoot}>
        <CircularProgress />
      </div>
    );
  }

  const today = new Date();
  const currentYear = getYear(today);
  const lastYear = getYear(subYears(today, 1));

  const details: [string, () => string | undefined | JSX.Element][] = [
    ['Name:', toText(product?.productName)],
    ['SKU:', toText(product?.productCode)],
    ['Category:', toText(product?.category)],
    ['Product Tier:', toText(product?.productTier)],
    ['Current Price:', toNumber(product?.currentPrice)],
    ['FIFO Cost:', toNumber(product?.productCostUnit.toFixed(2))],
    [
      'Product Margin:',
      toNumber((Number(product?.productMargin) * 100).toFixed(1), true),
    ],
    [`${currentYear} Sales`, toNumber(product?.details?.ytdSales)],
    [`${currentYear} Quantity Sold`, toText(product?.details?.ytdqty)],
    [`${lastYear} Sales`, toNumber(product?.details?.lastYearSales)],
    [`${lastYear} Quantity Sold`, toText(product?.details?.lastYearQTY)],
  ];

  return (
    <div>
      <Button className={classes.goBack} variant='outlined' onClick={goBack}>
        Go Back
      </Button>
      <div className={classes.grid}>
        <div>
          <Typography>Details</Typography>
          <div className={classes.gridDetails}>
            {details.map(([label, detail], i) => (
              <div key={i}>
                <label>
                  <strong>{label}</strong>
                </label>
                <p>{detail()}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <Typography>Price History</Typography>
          {product && (
            <Chart options={chartOptions} series={chartData} type='line' />
          )}
        </div>
        {/* <pre>{JSON.stringify(product, null, 2)}</pre> */}
      </div>
    </div>
  );
}
