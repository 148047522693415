import {
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  useScrollTrigger,
} from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  IdType,
  TableInstance,
  TableOptions,
  TableState,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  UseTableCellProps,
} from 'react-table';

import TablePagination from './table-pagination';
import TableToolBar from './table-toolbar';
import FilterChipBar from './filter-chipbar';
import { useDebounce } from '../../hooks/useDebounce';
import { useScrollSync } from '../../hooks/useScrollSync';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    //maxHeight: '670px',
  },
  stickyHeader: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 5,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    overflowX: 'hidden',
    padding: '0 24px',
  },
}));
export interface DataTableProps<T extends object> extends TableOptions<T> {
  totalPageCount: number;
  totalCount: number;
  initialState: Partial<TableState<T>>;
  hiddenColumns?: IdType<T>[];
  exportAction?: (instance: TableInstance<T>) => JSX.Element;
  searchAction?: (instance: TableInstance<T>) => JSX.Element;
  showAllAction?: (instance: TableInstance<T>) => JSX.Element;
  editAction?: (instance: TableInstance<T>) => JSX.Element;
  renderEdit?: (instance: TableInstance<T>) => JSX.Element;
  manual?: boolean;
  saveState: (value: any) => void;
}
export default function DataTable<T extends object>(
  props: DataTableProps<T>
): JSX.Element {
  const classes = useStyles();
  const {
    totalPageCount,
    totalCount,
    manual = false,
    initialState,
    saveState,
    hiddenColumns,
  } = props;
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  useScrollSync([tableContainerRef, stickyHeaderRef], { throttleWaitTime: 0 });
  const showHeader = useScrollTrigger({
    threshold: 200,
    disableHysteresis: true,
  });

  const firstRender = useRef(true);
  const defaultColumn = useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const instance = useTable<T>(
    {
      ...props,
      defaultColumn,
      initialState,
      manualPagination: manual,
      manualSortBy: manual,
      manualFilters: manual,
      manualGlobalFilter: manual,
      disableMultiSort: true,
      autoResetPage: false,
      pageCount: totalPageCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useBlockLayout,
    hooks => {
      hooks.visibleColumns.push(columns => {
        return [
          {
            id: 'selection',
            width: 75,
            groupByBoundary: true,
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }: UseTableCellProps<T>) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    },
    hooks => {
      hooks.stateReducers.push((state, action, previousState, instance) => {
        if (action.type === 'init') {
          return {
            ...state,
            showAll: instance?.initialState.showAll || false,
          };
        }

        if (action.type === 'reset-show-all') {
          return {
            ...state,
            showAll: instance?.initialState.showAll || false,
          };
        }

        if (action.type === 'toggle-show-all') {
          return {
            ...state,
            showAll: !previousState?.showAll,
          };
        }
      });
      hooks.useInstance.push(function useShowAllInstance(
        instance: TableInstance<T>
      ) {
        const { dispatch } = instance;

        const toggleShowAll = useCallback(() => {
          dispatch({ type: 'toggle-show-all' });
          dispatch({ type: 'gotoPage', pageIndex: 0 });
        }, [dispatch]);

        const resetShowAll = useCallback(() => {
          dispatch({ type: 'reset-show-all' });
        }, [dispatch]);

        Object.assign(instance, { toggleShowAll, resetShowAll });
      });
    }
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    state,
    setHiddenColumns,
  } = instance;

  const debouncedState = useDebounce(state, 500);

  useEffect(() => {
    setHiddenColumns(hiddenColumns || []);
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    const {
      pageIndex,
      pageSize,
      sortBy,
      filters,
      globalFilter,
      showAll,
      hiddenColumns,
    } = debouncedState;
    if (!firstRender.current) {
      saveState({
        pageIndex,
        pageSize,
        sortBy,
        filters,
        globalFilter,
        showAll,
        hiddenColumns,
      });
    }
    firstRender.current = false;
  }, [debouncedState, saveState]);

  const rows = page.map((row, i) => {
    prepareRow(row);
    return (
      <TableRow hover {...row.getRowProps()}>
        {row.cells.map(cell => (
          <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
        ))}
      </TableRow>
    );
  });
  return (
    <>
      <TableToolBar<T> instance={instance} />
      <FilterChipBar<T> instance={instance} />
      <TableContainer
        innerRef={tableContainerRef}
        classes={{ root: classes.tableContainer }}>
        {showHeader && (
          <div ref={stickyHeaderRef} className={classes.stickyHeader}>
            {headerGroups.map(headerGroup => (
              <TableRow component='div' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell component='div' {...column.getHeaderProps()}>
                    <strong>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </strong>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </div>
        )}
        <Table size='small' {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps()}>
                    <strong>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </strong>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>{rows}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination<T> instance={instance} total={totalCount} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
