import {
  Dialog,
  makeStyles,
  Button,
  Icon,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useService } from '../../shared/contexts/service-context';
import useCsvFile from '../../shared/hooks/useCsvFile';
import useProgress from '../../shared/hooks/useProgress';
import {
  ProductUploadFile,
  ProductUploadRevision,
} from '../../shared/models/product';
import { UploadProductsProps } from '../../shared/services/product-service';
import {
  calculateMargin,
  chunkArray,
  compareToMarginFloor,
  DATE_TIME_MIN,
} from '../../shared/utility/helpers';
import OverrideUntilPicker from '../override-until-picker';
import RevisionSelect from '../revision-select';

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  fileInput: {
    display: 'none',
  },
  content: {
    minHeight: 300,
  },
  error: {
    color: 'red',
  },
}));

const ACCEPTED_FIELDS = [
  'ProductCode',
  'ProductCostUnit',
  'MarginFloor',
  'CurrentPrice',
  'RecommendedPrice',
  'RevisedPrice',
  'QuantityBreak2',
  'QuantityBreak3',
  'QuantityBreak4',
  'QuantityBreak5',
];
const CHUNK_SIZE = 50;

export default function UploadModal({ open, onClose }: UploadModalProps) {
  const classes = useStyles();
  const [
    file,
    error,
    inputRef,
    onFileChange,
    trigger,
    clearData,
  ] = useCsvFile<ProductUploadFile>(ACCEPTED_FIELDS);
  const { productService, toastService } = useService();
  const [progress, setTotal, incrementProgress] = useProgress();
  const [loading, setLoading] = useState(false);
  const { control, watch, errors } = useForm({
    mode: 'onBlur',
    defaultValues: {
      revisionReason: null,
      overrideUntil: DATE_TIME_MIN,
    },
  });
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation<string, Error, UploadProductsProps>(
    request => productService.uploadProducts(request)
  );

  const revisionReason = watch('revisionReason');
  const overrideUntil = watch('overrideUntil');

  const productsForAutoApproval = file.data.filter(
    csv => csv.RevisedPrice === csv.RecommendedPrice
  );

  const productsWithChanges = file.data.filter(
    csv => csv.RevisedPrice !== csv.RecommendedPrice
  );

  const productsBelowMargin = productsWithChanges.filter(csv =>
    compareToMarginFloor(
      calculateMargin(csv.RevisedPrice, csv.ProductCostUnit),
      csv.MarginFloor
    )
  );

  const resultHasApprovals = Boolean(productsForAutoApproval.length);
  const resultHasChanges = Boolean(productsWithChanges.length);
  const changesAreBelowMarginFloor = Boolean(productsBelowMargin.length);
  const hasError = Boolean(error);
  const noFileData = !Boolean(file.data.length);
  const hasChangesButNoReason = resultHasChanges && !Boolean(revisionReason);
  const disableSave = Boolean(
    hasError || noFileData || hasChangesButNoReason || loading
  );

  const closeDialog = () => {
    onClose();
    clearData();
  };

  const save = async () => {
    const mappedApprovals = productsForAutoApproval.map(product => ({
      ...product,
      RevisionReason: null,
      OverrideUntil: DATE_TIME_MIN,
    }));
    const mappedChanges = productsWithChanges.map(product => ({
      ...product,
      RevisionReason: revisionReason,
      OverrideUntil: overrideUntil,
    }));

    const products = [...mappedApprovals, ...mappedChanges];
    const chunks = chunkArray<ProductUploadRevision>(products, CHUNK_SIZE);
    setLoading(true);
    setTotal(chunks.length);
    try {
      for (const chunk of chunks) {
        await mutateAsync({ products: chunk });
        incrementProgress();
      }
      queryClient.invalidateQueries('products');
      closeDialog();
      toastService.success('Upload Successful!');
    } catch (error) {
      toastService.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog disableBackdropClick={true} fullWidth open={open}>
      <DialogContent className={classes.content}>
        <DialogContentText>Upload File</DialogContentText>
        <div>
          <Button onClick={trigger}>
            Choose file
            <Icon>upload_file</Icon>
          </Button>
          <input
            ref={inputRef}
            onChange={onFileChange}
            accept='.csv'
            className={classes.fileInput}
            type='file'
          />
          <Typography variant='caption'>{file?.name}</Typography>
        </div>
        {error ? (
          <p className={classes.error}>{error}</p>
        ) : (
          <div>
            {loading && (
              file.data.length < CHUNK_SIZE ? (
                <CircularProgress variant='indeterminate' />
              ) : (
                <LinearProgress variant='determinate' value={progress} />
              )
            )}
            {resultHasApprovals && (
              <p>
                <strong>{productsForAutoApproval.length}</strong> Products will
                be marked <strong>Approved</strong>
              </p>
            )}
            {resultHasChanges && (
              <div>
                <p>
                  <strong>{productsWithChanges.length}</strong> Products have
                  revisions. Please select a reason and Override until date
                  (optional)
                </p>
                <div>
                  <RevisionSelect
                    control={control}
                    name='revisionReason'
                    label='Select a reason'
                    rules={{
                      required: { value: true, message: 'Reason is required' },
                    }}
                    error={Boolean(errors.revisionReason)}
                    helperText={
                      Boolean(errors.revisionReason)
                        ? errors.revisionReason?.message
                        : ''
                    }
                  />
                  <OverrideUntilPicker
                    control={control}
                    name='overrideUntil'
                    label='Override Until'
                  />
                </div>
                {changesAreBelowMarginFloor && (
                  <p>
                    <strong>{productsBelowMargin.length}</strong> Changed
                    products are below margin
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          onClick={save}
          variant='contained'
          color='primary'
          disabled={disableSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
