import 'fontsource-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
//import reportWebVitals from './reportWebVitals';
import DateFnsUtils from '@date-io/date-fns';
import {
    ThemeProvider,
    unstable_createMuiStrictModeTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ProvideAuth } from './shared/contexts/auth-context';
import { ProvideService } from './shared/contexts/service-context';
import ProductsService from './shared/services/product-service';
import ToastService from './shared/services/toast-service';
import { getAuthConfig } from './startup/getAuthConfig';
import { getAuthInstance } from './startup/getAuthInstance';

const run = async () => {
    const theme = unstable_createMuiStrictModeTheme({
        palette: {
            primary: {
                main: '#00446a',
                light: '#406f98',
                dark: '#001e3f',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#f08b1d',
                light: '#ffbc51',
                dark: '#b85d00',
                contrastText: '#000000',
            },
            background: {
                default: '#E1E2E1',
                paper: '#F5F5F6',
            },
        },
        typography: {
            // fontWeightSemiBold: 600,
            // fontWeightExtraBold: 800,
        },
    });
    const queryClient = new QueryClient();
    const baseUrl = process.env.PUBLIC_URL;
    const authConfig = await getAuthConfig(baseUrl);
    const userManager = await getAuthInstance(authConfig, baseUrl);
    const toastService = new ToastService();
    const productService = new ProductsService(
        userManager,
        authConfig,
        toastService,
        baseUrl
    );
    ReactDOM.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ProvideAuth context={{ userManager, authConfig }}>
                    <ProvideService context={{ productService, toastService }}>
                        <BrowserRouter basename={baseUrl}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={theme}>
                                    <App />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </BrowserRouter>
                    </ProvideService>
                </ProvideAuth>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

run().catch(console.error);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
