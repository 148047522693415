import { makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FilterProps } from 'react-table';
import { Product } from '../../shared/models/product';
import {format} from 'date-fns';

const useStyles = makeStyles(theme => ({
  control: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center'
  }
}))

export default function DateFilter({
  column: { filterValue = [], setFilter, render, id },
}: FilterProps<Product>) {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.control}>
        <DatePicker
          label={`${render('Header')} Since`}
          value={filterValue[0] || undefined}
          onChange={date => {
            setFilter([format(date as Date, 'MM-dd-yy')]);
          }}
          maxDate={new Date()}
          format='MM-dd-yy'
        />
      </div>
    </div>
  );
}
