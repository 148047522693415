export interface AuthConfig {
  clientId: string;
  authorityUrl: string;
  scope: string;
}

export const getAuthConfig = async (baseUrl: string) => {
  const response = await fetch(`${baseUrl}/api/config`);
  const config: AuthConfig = await response.json();
  return config;
};
