import { createContext, PropsWithChildren, useContext } from 'react';
import { UserAgentApplication } from 'msal';
import { AuthConfig } from '../../startup/getAuthConfig';

interface AuthContext {
  userManager: UserAgentApplication;
  authConfig: AuthConfig;
}
type AuthProviderProps = {
  context: AuthContext;
};

const authContext = createContext<AuthContext>({} as AuthContext);

export function ProvideAuth({
  children,
  context,
}: PropsWithChildren<AuthProviderProps>) {
  return (
    <authContext.Provider value={context}>{children}</authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}
