import { useCallback } from 'react';
import { IdType, TableInstance } from 'react-table';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  chipLabel: {
    marginRight: 4,
  },
  title: {
    paddingRight: 10,
  },
  chipZone: {
    marginLeft: 20,
    padding: '10px 0 15px 10px',
    width: '100%',
  },
  chip: {
    marginRight: 7,
  },
}));

type FilterChipBarProps<T extends object> = {
  instance: TableInstance<T>;
};

export default function FilterChipBar<T extends object>({
  instance,
}: FilterChipBarProps<T>) {
  const classes = useStyles();
  const {
    allColumns,
    setFilter,
    state: { filters },
  } = instance;
  const handleDelete = useCallback(
    (id: string | number) => {
      setFilter(id as IdType<T>, undefined);
    },
    [setFilter]
  );

  return Object.keys(filters).length > 0 ? (
    <div className={classes.chipZone}>
      <span className={classes.title}>Active filters:</span>
      {filters &&
        allColumns.map(column => {
          const filter = filters.find(f => f.id === column.id);
          const value = filter && filter.value;
          return (
            value && (
              <Chip
                className={classes.chip}
                color='secondary'
                key={column.id}
                label={
                  <>
                    <span className={classes.chipLabel}>
                      {column.render('Header')}:{' '}
                    </span>
                    {value instanceof Array ? `Since ${value[0]}` : value}
                  </>
                }
                onDelete={() => handleDelete(column.id)}
                variant='outlined'
              />
            )
          );
        })}
    </div>
  ) : null;
}
