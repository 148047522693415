import { Configuration, Logger, UserAgentApplication } from 'msal';
import { AuthConfig } from './getAuthConfig';

export const getAuthInstance = async (
  authConfig: AuthConfig,
  baseUrl: string
) => {
  const msalConfig: Configuration = {
    auth: {
      clientId: authConfig.clientId,
      authority: authConfig.authorityUrl,
      redirectUri: `${window.location.protocol}//${window.location.host}/signin-callback`,
      validateAuthority: false,
      postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}${baseUrl}`,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      logger: new Logger((loglevel, message) => {
        // console.log(message);
      }, {}),
    },
  };

  return new UserAgentApplication(msalConfig);
};
