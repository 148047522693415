import { PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import ROUTES from '../../../routes';
import { useAuth } from '../../contexts/auth-context';

export default function PrivateRoute({
  children,
  ...rest
}: PropsWithChildren<RouteProps>) {
  const { userManager } = useAuth();
  const userAccount = userManager.getAccount();
  const authenticated = Boolean(userAccount);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
