export interface Product {
  productCode: string;
  productName: string;
  category: string;
  upc: string;
  productTier: string;
  competitiveSku: string;
  reasonForRecommendation: string;
  revisionReason: string;
  currentPrice: number;
  productCostUnit: number;
  quantityBreak2: number;
  quantityBreak3: number;
  quantityBreak4: number;
  quantityBreak5: number;
  quantityBreaksUsed: string;
  recommendedPrice: number;
  revisedPrice: number;
  competitiveSkuPrice: number;
  productMargin: number;
  revisedMargin: number;
  priceChange: number;
  marginFloor: number;
  status: string;
  lastApproved: Date;
  overrideUntil: Date;
  productHistory?: ProductHistory[];
  details?: ProductDetail;
  competitors: ProductCompetitor[];
}

export interface History {
  oldPrice: number;
  newPrice: number;
  updatedAt: string;
}
export interface ProductHistory extends History {}

export interface CompetitorHistory extends History {}

export interface ProductDetail {
  lastYearSales: number;
  lastYearQTY: string;
  ytdSales: number;
  ytdqty: string;
}
export interface ProductCompetitor {
  brand: string;
  name: string;
  site: string;
  url: string;
  price: number;
  description: string;
  lastUpdated: string;
  competitorHistory: CompetitorHistory[];
}

export interface ProductPagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  totalPages: number;
}

export type PaginationResponse = ProductPagination & {
  products: Product[];
};

export enum ProductStatus {
  Approved = 'Approved',
  Review = 'Review',
  ExecReview = 'Exec Review',
}

export type ProductStatusOptions = 'Approved' | 'Review' | 'Exec Review';

export interface ProductUploadFile {
  ProductCode: string;
  ProductCostUnit: number;
  MarginFloor: number;
  CurrentPrice: number;
  RecommendedPrice: number;
  RevisedPrice: number;
  QuantityBreak2: number;
  QuantityBreak3: number;
  QuantityBreak4: number;
  QuantityBreak5: number;
};

export interface ProductRevision {
  overrideUntil: Date;
  revisedPrice: string;
  quantityBreak2: string;
  quantityBreak3: string;
  quantityBreak4: string;
  quantityBreak5: string;
}

export interface ProductUploadRevision {
  ProductCode: string;
  RevisedPrice: number;
  QuantityBreak2: number;
  QuantityBreak3: number;
  QuantityBreak4: number;
  QuantityBreak5: number;
}
